import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import { axiosConfig } from '../axiosConfig'
import { PsoConfig } from '../../constants/createNew'
import { Bars } from './useCreateBar'

type UpdateBar = { barId: number; values: PsoConfig }

const updateBar = ({ barId, values }: UpdateBar) =>
  axios.put(`/pso/bars/${barId}`, values, axiosConfig).then(({ data }) => data)

export const useUpdateBar = () => {
  const queryClient = useQueryClient()

  const onSuccess = (_: any, formValues: UpdateBar) => {
    queryClient.setQueryData('psoBars', (oldBars: Bars[] = []) =>
      oldBars.map(bar =>
        bar.id === formValues.barId
          ? {
              ...bar,
              name: formValues.values.name,
            }
          : bar
      )
    )
    queryClient.setQueryData(['psoBar', formValues.barId], formValues.values)
  }

  return useMutation(updateBar, { onSuccess })
}
