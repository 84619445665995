import React from 'react'
import PropTypes from 'prop-types'

import { TemplateItem } from './TemplateItem'
import { config } from '../constants/templates'

export const TemplateList = ({ onClick }) =>
  config.map(({ label, styles }) => (
    <TemplateItem key={label} label={label} onClick={onClick} styles={styles} />
  ))

TemplateList.propTypes = {
  onClick: PropTypes.func.isRequired,
}
