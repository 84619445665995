export const fontSizeOptions = [
  { text: '8px', value: '8px' },
  { text: '9px', value: '9px' },
  { text: '10px', value: '10px' },
  { text: '11px', value: '11px' },
  { text: '12px', value: '12px' },
  { text: '14px', value: '14px' },
  { text: '15px', value: '15px' },
  { text: '16px', value: '16px' },
  { text: '18px', value: '18px' },
  { text: '20px', value: '20px' },
  { text: '22px', value: '22px' },
  { text: '24px', value: '24px' },
  { text: '26px', value: '26px' },
  { text: '28px', value: '28px' },
  { text: '30px', value: '30px' },
  { text: '32px', value: '32px' },
  { text: '34px', value: '34px' },
  { text: '36px', value: '36px' },
  { text: '38px', value: '38px' },
  { text: '40px', value: '40px' },
  { text: '44px', value: '44px' },
  { text: '48px', value: '48px' },
  { text: '56px', value: '56px' },
  { text: '64px', value: '64px' },
]

export const fontFamilyOptions = [
  {
    text: 'Amatic SC',
    value: 'Amatic SC',
    style: { fontFamily: 'Amatic SC', fontSize: '20px', fontWeight: 700 },
  },
  { text: 'Bangers', value: 'Bangers', style: { fontFamily: 'Bangers', fontSize: '20px' } },
  { text: 'Caveat', value: 'Caveat', style: { fontFamily: 'Caveat', fontSize: '20px' } },
  {
    text: 'Inconsolata',
    value: 'Inconsolata',
    style: { fontFamily: 'Inconsolata', fontSize: '20px' },
  },
  { text: 'Lato', value: 'Lato', style: { fontFamily: 'Lato', fontSize: '20px' } },
  { text: 'Lobster', value: 'Lobster', style: { fontFamily: 'Lobster', fontSize: '20px' } },
  {
    text: 'Merriweather',
    value: 'Merriweather',
    style: { fontFamily: 'Merriweather', fontSize: '20px' },
  },
  { text: 'Pacifico', value: 'Pacifico', style: { fontFamily: 'Pacifico', fontSize: '20px' } },
  {
    text: 'Patrick Hand',
    value: 'Patrick Hand',
    style: { fontFamily: 'Patrick Hand', fontSize: '20px' },
  },
]

export const textTimeSecondsOptions = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
  { text: '4', value: '4' },
  { text: '5', value: '5' },
  { text: '6', value: '6' },
  { text: '7', value: '7' },
  { text: '8', value: '8' },
  { text: '9', value: '9' },
  { text: '10', value: '10' },
]
