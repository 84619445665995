import { useFormikContext } from 'formik'
import { Segment, SegmentGroup } from 'semantic-ui-react'
import SegmentHeader from './SegmentHeader'
import { FormCheckbox, FormColorPicker, FormDropdown } from '../form'
import { fontFamilyOptions, fontSizeOptions } from '../../constants/options'
import { commonFormFields } from '../../constants/createNew'

import './Step3ColorsAndFonts.scss'

export const Step3ColorsAndFonts = ({ open, onClick }) => {
  const { values } = useFormikContext()

  const shouldShowBackgroundColorPicker = !values.styles.backgroundImage

  const handleHeaderClick = () => {
    onClick(!open)
  }

  return (
    <SegmentGroup>
      <SegmentHeader onClick={handleHeaderClick} open={open} title='Krok 3. Kolory i czcionki' />
      {open && (
        <Segment padded>
          {shouldShowBackgroundColorPicker && (
            <FormColorPicker
              disableAlpha={false}
              label='Wybierz kolor tła:'
              name='styles.backgroundColor'
            />
          )}
          <FormColorPicker label='Wybierz kolor czcionki:' name='styles.color' />
          <FormCheckbox
            className='colors-and-fonts__font-from-shop'
            name={commonFormFields.fontFromShop}
            label='Ustaw taką samą czcionkę jaka jest w moim sklepie'
          />
          <FormDropdown
            disabled={values.fontFromShop === true}
            selection
            options={fontFamilyOptions}
            name='styles.fontFamily'
            label='Wybierz czcionkę:'
          />
          <FormDropdown
            selection
            options={fontSizeOptions}
            name='styles.fontSize'
            label='Wybierz rozmiar czcionki na komputerach:'
          />
          <FormDropdown
            selection
            options={fontSizeOptions}
            name='styles.fontSizeMobile'
            label='Wybierz rozmiar czcionki na mobile:'
          />
        </Segment>
      )}
    </SegmentGroup>
  )
}
