import { Button } from 'semantic-ui-react'

import './SubmitButtons.scss'

export const SubmitButtons = ({ onCancel, isLoading }) => {
  return (
    <div className='submit-buttons'>
      <Button basic type='button' disabled={isLoading} onClick={onCancel}>
        Anuluj
      </Button>
      <Button primary type='submit' disabled={isLoading} loading={isLoading}>
        Zapisz
      </Button>
    </div>
  )
}
