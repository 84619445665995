export const EDisplayOnSites = {
  all: 'all',
  homepage: 'homepage',
  cart: 'cart',
}

export const EPosition = {
  bottom: 'bottom',
  top: 'top',
}

export const configs = {
  positionConfig: [
    {
      label: 'Na górze',
      value: EPosition.top,
    },
    {
      label: 'Na dole',
      value: EPosition.bottom,
    },
  ],
  showOnPages: [
    {
      label: 'Wszystkich stronach',
      value: EDisplayOnSites.all,
    },
    {
      label: 'Tylko na stronie głównej',
      value: EDisplayOnSites.homepage,
    },
    {
      label: 'Tylko na stronie koszyka',
      value: EDisplayOnSites.cart,
    },
  ],
}

export const commonFormFields = {
  fontFromShop: 'fontFromShop',
}

///// PDD

export type PddConfig = {
  displayOnSites: typeof EDisplayOnSites
  fontFromShop: boolean
  name: string
  position: typeof EPosition
  showFrom: boolean
  showFromAmount: number
  showOnCart: boolean
  showOnMobile: boolean
  showOnScroll: boolean
  styles: {
    backgroundColor: string
    color: string
    fontFamily: string
    fontSize: string
    fontSizeMobile: string
  }
  texts: {
    [key: string]: {
      active: boolean
      goal: string
      initialMessageBefore: string
      initialMessageAfter: string
      inProgressMessageBefore: string
      inProgressMessageAfter: string
      reachedGoalMessage: string
    }
  }
}

export const pddFormField = {
  displayOnSites: 'displayOnSites',
  goal: 'goal',
  name: 'name',
  position: 'position',
  showFrom: 'showFrom',
  showFromAmount: 'showFromAmount',
  showOnCart: 'showOnCart',
  showOnMobile: 'showOnMobile',
  showOnScroll: 'showOnScroll',
}

export const initialPddValues = {
  [commonFormFields.fontFromShop]: false,
  [pddFormField.displayOnSites]: EDisplayOnSites.all,
  [pddFormField.name]: 'Mój pasek darmowej dostawy',
  [pddFormField.position]: EPosition.top,
  [pddFormField.showFrom]: false,
  [pddFormField.showFromAmount]: 0,
  [pddFormField.showOnCart]: false,
  [pddFormField.showOnMobile]: true,
  [pddFormField.showOnScroll]: true,
  styles: {},
  texts: {
    pl_PL: {
      active: true,
      goal: '100',
      initialMessageBefore: 'Darmowa dostawa od ',
      initialMessageAfter: ' zł',
      inProgressMessageBefore: 'Jeszcze tylko ',
      inProgressMessageAfter: ' zł, aby otrzymać darmową dostawę!',
      reachedGoalMessage: 'Gratulacje! Otrzymujesz darmową dostawę',
    },
    en_US: {
      active: false,
      goal: '100',
      initialMessageBefore: 'Free shipping over $',
      initialMessageAfter: '',
      inProgressMessageBefore: 'Only $',
      inProgressMessageAfter: ' left to get free shipping',
      reachedGoalMessage: 'Congratulations! You get free shipping',
    },
    en_GB: {
      active: false,
      goal: '100',
      initialMessageBefore: 'Free shipping over £',
      initialMessageAfter: '',
      inProgressMessageBefore: 'Only £',
      inProgressMessageAfter: ' left to get free shipping',
      reachedGoalMessage: 'Congratulations! You get free shipping',
    },
    de_DE: {
      active: false,
      goal: '100',
      initialMessageBefore: 'Kostenlose Lieferung ab €',
      initialMessageAfter: '',
      inProgressMessageBefore: 'Nur €',
      inProgressMessageAfter: ' mehr für kostenlose Lieferung',
      reachedGoalMessage: 'Glückwunsch! Sie erhalten kostenlose Lieferung',
    },
    sk_SK: {
      active: false,
      goal: '100',
      initialMessageBefore: 'Doručenie zdarma od €',
      initialMessageAfter: '',
      inProgressMessageBefore: 'Len o €',
      inProgressMessageAfter: ' viac na doručenie zdarma',
      reachedGoalMessage: 'Gratulujeme! Dostanete bezplatné doručenie',
    },
    cs_CZ: {
      active: false,
      goal: '100',
      initialMessageBefore: 'Doprava zdarma od ',
      initialMessageAfter: ' Kč',
      inProgressMessageBefore: 'Pouze o ',
      inProgressMessageAfter: ' Kč více pro získání doručení zdarma',
      reachedGoalMessage: 'Gratulujeme! Doručení máte zdarma',
    },
    hu_HU: {
      active: false,
      goal: '100',
      initialMessageBefore: 'Ingyenes szállítás HUF ',
      initialMessageAfter: '-tól',
      inProgressMessageBefore: 'Csak HUF ',
      inProgressMessageAfter: ' többet kap ingyenes szállítás',
      reachedGoalMessage: 'Gratulálunk! Ingyenes kiszállítás',
    },
  },
}

///// PSO

export type PsoConfig = {
  fontFromShop: boolean
  hasClose: boolean
  hasLink: boolean
  linkUrl: string
  name: string
  openLinkNewTab: boolean
  position: 'top' | 'bottom'
  showOnCart: boolean
  showOnMobile: boolean
  showOnPages: 'all' | 'homepage'
  showOnScroll: boolean
  textOne: string
  textTwo: string
  textThree: string
  textTimeSeconds: number
  styles: {
    backgroundColor: string
    color: string
    fontFamily: string
    fontSize: string
    fontSizeMobile: string
  }
  texts: {
    [key: string]: {
      textOne: string
      textTwo: string
      textThree: string
    }
  }
}

export const psoFormField = {
  hasClose: 'hasClose',
  hasLink: 'hasLink',
  linkUrl: 'linkUrl',
  name: 'name',
  openLinkNewTab: 'openLinkNewTab',
  position: 'position',
  showOnCart: 'showOnCart',
  showOnMobile: 'showOnMobile',
  showOnPages: 'showOnPages',
  showOnScroll: 'showOnScroll',
  textOne: 'textOne',
  textTwo: 'textTwo',
  textThree: 'textThree',
  textTimeSeconds: 'textTimeSeconds',
}

export const initialPsoValues = {
  [commonFormFields.fontFromShop]: false,
  [psoFormField.hasClose]: true,
  [psoFormField.hasLink]: false,
  [psoFormField.linkUrl]: '',
  [psoFormField.name]: 'Przykładowy pasek ogłoszenia',
  [psoFormField.openLinkNewTab]: false,
  [psoFormField.position]: EPosition.top,
  [psoFormField.showOnCart]: false,
  [psoFormField.showOnMobile]: true,
  [psoFormField.showOnPages]: EDisplayOnSites.all,
  [psoFormField.showOnScroll]: true,
  [psoFormField.textOne]: '',
  [psoFormField.textTwo]: '',
  [psoFormField.textThree]: '',
  [psoFormField.textTimeSeconds]: '4',
  styles: {},
  texts: {
    pl_PL: {
      textOne: 'Kliknij teraz i sprawdź nasze promocje!',
      textTwo: '',
      textThree: '',
    },
  },
}
