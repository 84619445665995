import axios from 'axios'
import { useQuery } from 'react-query'
import { axiosConfig } from '../axiosConfig'

type Data = { daysLeft: number; ended: boolean }

const getSubscription = () =>
  axios.get<Data>('/pdd/subscription', axiosConfig).then(({ data }) => data)

export const useGetSubscription = () => useQuery('/pdd/subscription', getSubscription)
