import React from 'react'
import { render } from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import 'semantic-ui-css/semantic.min.css'
import './index.scss'
import App from './components/App'
import { queryClient } from './api/queryClient'

render(
  <>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </>,
  document.getElementById('root')
)
