import { AxiosRequestConfig } from 'axios'
// @ts-ignore
import { getAppId, getPddKey, getShopId } from '../utils/shop'

const params = { shop: getShopId(), appId: getAppId() }
const timeout = 15 * 1000
const pddKey = getPddKey() || ''

export const axiosConfig: AxiosRequestConfig = {
  params,
  timeout,
  headers: { 'X-pdd-key': pddKey },
}
