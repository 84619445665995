import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Header, Loader, Segment, SegmentGroup } from 'semantic-ui-react'

import SegmentHeader from '../common/SegmentHeader'
import { BarsTablePDD } from './BarsTablePDD'
import { useGetBars } from '../../api/pdd/useGetBars'

import './BarsPDD.scss'

export const BarsPDD = ({ onCreateNew, onEdit, hasSubscriptionEnded }) => {
  const { data: bars = [], isLoading } = useGetBars()

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const isEmptyBarList = bars.length === 0
  const isBarsDisabled = isLoading || bars.length > 10
  const isSomeActive = bars.some(({ active }) => active)

  return (
    <SegmentGroup>
      <SegmentHeader
        disabled={isBarsDisabled}
        title='Moje paski darmowej dostawy'
        buttonLabel='Utwórz nowy'
        onClick={onCreateNew}
        primary
      />
      <Segment className='bars-pdd__content'>
        {isLoading ? (
          <Loader active content='Loading...' />
        ) : isEmptyBarList ? (
          <Segment placeholder>
            <Header
              as='h2'
              className='bars-pdd__placeholder'
              content='Brak pasków'
              subheader='Aby utworzyć nowy pasek kliknij przycisk powyżej.'
              textAlign='center'
            />
          </Segment>
        ) : (
          <BarsTablePDD
            data={bars}
            hasSubscriptionEnded={hasSubscriptionEnded}
            isSomeActive={isSomeActive}
            onEdit={onEdit}
          />
        )}
      </Segment>
    </SegmentGroup>
  )
}

BarsPDD.propTypes = {
  hasSubscriptionEnded: PropTypes.bool.isRequired,
  onCreateNew: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}
