import { useField } from 'formik'
import { Form } from 'semantic-ui-react'

export const FormCheckbox = props => {
  const [{ checked }, , { setValue }] = useField({ type: 'checkbox', ...props })

  const handleChange = (_, { checked }) => {
    setValue(checked)
  }

  return (
    <Form.Checkbox
      checked={checked}
      onChange={handleChange}
      toggle={props.toggle ?? true}
      {...props}
    />
  )
}
