import React, { useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'

import { useGetSubscription } from '../api/pdd/useGetSubscription'

const SHOW_MESSAGE_FROM_DAYS = 7

export const SubscriptionBox = ({ handleSubscriptionEnd }) => {
  const { data } = useGetSubscription()

  const [daysToEnd, setDaysToEnd] = useState(SHOW_MESSAGE_FROM_DAYS + 1)
  const [hasSubscriptionEnded, setHasSubscriptionEnded] = useState(false)
  let message

  useEffect(() => {
    if (data) {
      setDaysToEnd(data.daysLeft)
      setHasSubscriptionEnded(data.ended)
      handleSubscriptionEnd(data.ended)
    }
  }, [handleSubscriptionEnd, data])

  if (daysToEnd > 4) {
    message = `Zostało tylko ${daysToEnd} dni`
  } else if (daysToEnd > 1) {
    message = `Zostały tylko ${daysToEnd} dni`
  } else if (daysToEnd === 1) {
    message = 'Został tylko 1 dzień'
  } else {
    message = 'Został mniej niż jeden dzień'
  }

  return daysToEnd > SHOW_MESSAGE_FROM_DAYS ? null : (
    <Message visible warning>
      <Message.Content>
        <h5>
          {hasSubscriptionEnded
            ? 'Twoja subskrypcja wygasła. Opłać ją teraz.'
            : `${message} do końca Twojej subskrypcji. Opłać ją teraz.`}
        </h5>
      </Message.Content>
    </Message>
  )
}
