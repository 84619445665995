const commonStyles = {
  backgroundSize: 'contain',
  color: '#fff',
  fontSize: '24px',
  fontSizeMobile: '18px',
  fontWeight: 400,
  padding: '10px',
  textAlign: 'center',
}

export const config = [
  {
    label: 'Szybki i odważny',
    styles: {
      ...commonStyles,
      backgroundColor: '#ffd54f',
      color: '#ff5722',
      fontFamily: 'Bangers',
    },
  },
  {
    label: 'Bajkowy świat',
    styles: {
      ...commonStyles,
      backgroundColor: '#f8bbd0',
      fontFamily: 'Amatic SC',
      fontSize: '28px',
      fontSizeMobile: '22px',
    },
  },
  {
    label: 'Rosnąca trawa 🌱',
    styles: {
      ...commonStyles,
      backgroundColor: '#f1f8e9',
      color: '#8bc34a',
      fontFamily: 'Caveat',
      fontSize: '28px',
      fontSizeMobile: '22px',
    },
  },
  {
    label: 'Spokojny sen',
    styles: {
      ...commonStyles,
      backgroundColor: '#8aaae5',
      color: '#fff',
      fontFamily: 'Lobster',
    },
  },
  {
    label: 'Hotel Ibiza',
    styles: {
      ...commonStyles,
      backgroundColor: '#292929',
      color: '#f9d443',
      fontFamily: 'Lato',
      textTransform: 'uppercase',
    },
  },
  {
    label: 'Urodzinowa niespodzianka',
    styles: {
      ...commonStyles,
      backgroundColor: '#faf5ef',
      backgroundImage:
        'url("https://pdd-pso-backgrounds.s3.eu-central-1.amazonaws.com/playful-small.jpg")',
      color: '#e33b3b',
      fontFamily: 'Bangers',
    },
  },
  {
    label: 'Pierwsza gwiazdka',
    styles: {
      ...commonStyles,
      backgroundColor: '#fffdfc',
      backgroundImage:
        'url("https://pdd-pso-backgrounds.s3.eu-central-1.amazonaws.com/christmas1-small.jpg")',
      color: '#943a3a',
      fontFamily: 'Pacifico',
      backgroundPosition: 'center',
    },
  },
  {
    label: 'Mikołajkowy prezent',
    styles: {
      ...commonStyles,
      backgroundColor: '#c53433',
      backgroundImage:
        'url("https://pdd-pso-backgrounds.s3.eu-central-1.amazonaws.com/christmas2-small.jpg")',
      color: '#ffffff',
      fontFamily: 'Lobster',
    },
  },
  {
    label: 'Walentynkowa promocja',
    styles: {
      ...commonStyles,
      backgroundColor: '#feffff',
      backgroundImage:
        'url("https://pdd-pso-backgrounds.s3.eu-central-1.amazonaws.com/colorful-polka-dots-with-hearts-vector-small.jpg")',
      color: '#A82727',
      fontFamily: 'Caveat',
      fontSize: '28px',
      fontSizeMobile: '22px',
      backgroundSize: 'auto',
    },
  },
  {
    label: 'Leśna polana',
    styles: {
      ...commonStyles,
      backgroundColor: '#ccface',
      backgroundImage:
        'url("https://pdd-pso-backgrounds.s3.eu-central-1.amazonaws.com/green-chaos-small.png")',
      color: '#376A2B',
      fontFamily: 'Merriweather',
      fontSize: '20px',
      fontSizeMobile: '18px',
    },
  },
  {
    label: 'Morska bryza',
    styles: {
      ...commonStyles,
      backgroundColor: '#21b4c6',
      backgroundImage:
        'url("https://pdd-pso-backgrounds.s3.eu-central-1.amazonaws.com/waves.jpeg")',
      color: '#ffffff',
      fontFamily: 'Lobster',
    },
  },
]
