import axios from 'axios'
import { isNil } from 'lodash'
import { useMutation, useQueryClient } from 'react-query'

import { axiosConfig } from '../axiosConfig'
import { Bars } from './useCreateBar'
import { PddConfig } from '../../constants/createNew'

type UpdateBar = {
  barId: number
  config?: PddConfig
  active?: boolean
}

const updateBar = ({ barId, active, config }: UpdateBar) =>
  axios.put(`/pdd/bars/${barId}`, { active, config }, axiosConfig).then(({ data }) => data)

export const useUpdateBar = () => {
  const queryClient = useQueryClient()

  const onSuccess = (_: any, { barId, active, config }: UpdateBar) => {
    queryClient.setQueryData('pddBars', (oldBars: Bars[] = []) =>
      oldBars.map(bar =>
        bar.id === barId
          ? {
              ...bar,
              active: isNil(active) ? bar.active : active,
              name: isNil(active) ? config?.name || '' : bar.name,
            }
          : bar
      )
    )

    if (config) {
      queryClient.setQueryData(['pddBar', barId], config)
    }
  }

  return useMutation(updateBar, { onSuccess })
}
