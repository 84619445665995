import { CreateNewPSO } from './CreateNewPSO'
import { useCreateBar } from '../../api/pso/useCreateBar'
import { Form } from '../form'
import { initialPsoValues } from '../../constants/createNew'
import { useUpdateBar } from '../../api/pso/useUpdateBar'
import { psoValidationSchema } from '../../utils/validation'

export const PsoTab = ({ barId, onSave, onCancel, setIsTemplateClicked, isTemplateClicked }) => {
  const { mutateAsync: createBar, isLoading: isCreateLoading } = useCreateBar()
  const { mutateAsync: updateBar, isLoading: isUpdateLoading } = useUpdateBar()

  const isSubmitting = isCreateLoading || isUpdateLoading

  const handleCreateBar = async values => {
    await createBar(values)
    onSave()
  }

  const handleUpdateBar = async values => {
    await updateBar({ barId, values })
    onSave()
  }

  const handleSubmit = async values => {
    barId ? await handleUpdateBar(values) : await handleCreateBar(values)
  }

  return (
    <Form
      initialValues={initialPsoValues}
      onSubmit={handleSubmit}
      validationSchema={psoValidationSchema}
    >
      <CreateNewPSO
        barId={barId}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
        setIsTemplateClicked={setIsTemplateClicked}
        showStep1Button={isTemplateClicked}
      />
    </Form>
  )
}
