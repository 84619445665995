import React from 'react'
import { Button, Checkbox, Popup } from 'semantic-ui-react'

import Table from '../common/Table'
import { useToggleBarActive } from '../../api/pso/useToggleBarActive'
import { useDeleteBar } from '../../api/pso/useDeleteBar'
import { mixpanelTrack } from '../../utils/mixpanel'

export const BarsTablePSO = ({ bars, onEdit, isSomeActive, hasSubscriptionEnded }) => {
  const { mutateAsync: toggleBarActive, isLoading: isToggleBarLoading } = useToggleBarActive()
  const { mutateAsync: deleteBar, isLoading: isDeleteLoading } = useDeleteBar()

  const isLoading = isToggleBarLoading || isDeleteLoading

  const handleDelete = barId => async () => {
    const hasConfirmed = window.confirm('Czy na pewno chcesz usunąć wybrany pasek?')

    if (hasConfirmed) {
      await deleteBar(barId)
    }
  }

  const handleActivate = (barId, isActive) => async () => {
    if (hasSubscriptionEnded && !isActive) return

    await toggleBarActive({ barId, isActive: !isActive })

    const action = isActive ? 'Wyłącz' : 'Włącz'
    mixpanelTrack(`${action} PSO`)
  }

  const config = [
    {
      width: 2,
      title: 'Lp.',
      render: (_, index) => <p>{index + 1}</p>,
    },
    {
      width: 11,
      title: 'Nazwa',
      field: 'name',
    },
    {
      width: 1,
      title: 'Edytuj',
      render: ({ id }) => <Button basic disabled={isLoading} icon='edit' onClick={onEdit(id)} />,
    },
    {
      width: 1,
      title: 'Usuń',
      render: ({ id }) => (
        <Button basic disabled={isLoading} icon='trash' onClick={handleDelete(id)} />
      ),
    },
    {
      width: 1,
      title: 'Aktywuj',
      render: ({ id, active }) => {
        return active || !isSomeActive ? (
          <Checkbox
            checked={active}
            disabled={isLoading}
            onChange={handleActivate(id, active)}
            toggle
          />
        ) : (
          <Popup
            basic
            content='Tylko jeden pasek może być aktywny w danym momencie. Aby aktywować, wyłącz pozostałe.'
            trigger={<Checkbox checked={active} disabled={isSomeActive} toggle />}
          />
        )
      },
    },
  ]

  return <Table config={config} data={bars} selectable />
}
