import React from 'react'
import { Image } from 'semantic-ui-react'

import appEKO from '../../static/images/EKO-tile.png'
import appRZM from '../../static/images/RZM-tile.png'
import appRZP from '../../static/images/RZP-tile.png'
import appESN from '../../static/images/ESN-tile.jpg'

import { mixpanelTrack } from '../../utils/mixpanel'

import './OtherApps.scss'

const EKO_URL = 'https://www.shoper.pl/aplikacje/efekty-na-kazda-okazje/'
const RZM_URL = 'https://www.shoper.pl/aplikacje/raport-zamowien/'
const RZP_URL = 'https://www.shoper.pl/aplikacje/raport-zamowien-pro/'
const ESN_URL = 'https://www.shoper.pl/aplikacje/efekt-sniegu/'

const OtherApps = () => {
  const handleClick = appName => () => {
    mixpanelTrack('Klik w kafelkę ' + appName)
  }

  return (
    <div className='other-apps'>
      <p>Inne nasze aplikacje, które mogą Ci się spodobać:</p>
      <div className='other-apps__images'>
        <Image
          src={appEKO}
          as='a'
          size='medium'
          href={EKO_URL}
          target='_blank'
          onClick={handleClick('EKO')}
        />
        <Image
          src={appESN}
          as='a'
          size='medium'
          href={ESN_URL}
          target='_blank'
          onClick={handleClick('ESN')}
        />
        <Image
          src={appRZM}
          as='a'
          size='medium'
          href={RZM_URL}
          target='_blank'
          onClick={handleClick('RZM')}
        />
        <Image
          src={appRZP}
          as='a'
          size='medium'
          href={RZP_URL}
          target='_blank'
          onClick={handleClick('RZP')}
        />
      </div>
    </div>
  )
}

export default OtherApps
