import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, Icon, Popup, Segment } from 'semantic-ui-react'

import './SegmentHeader.scss'

const SegmentHeader = ({
  buttonLabel,
  className,
  content,
  disabled,
  onClick,
  open,
  primary,
  questionIconText,
  showButton = true,
  sub,
  title,
}) => {
  const classes = classnames('segment-header', className)
  return (
    <Segment className={classes}>
      <div>
        <div className='segment-header__title'>
          <h3>{title}</h3>
          {questionIconText && (
            <Popup
              content={questionIconText}
              trigger={<Icon className='segment-header__icon' name='question circle outline' />}
              wide
            />
          )}
        </div>
        {sub && <h5>{sub}</h5>}
      </div>
      {content}
      {onClick && showButton && (
        <Button
          basic={!primary}
          disabled={disabled}
          onClick={onClick}
          primary={primary}
          type='button'
        >
          {buttonLabel || (open ? 'Ukryj' : 'Pokaż')}
        </Button>
      )}
    </Segment>
  )
}

SegmentHeader.propTypes = {
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.element,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  primary: PropTypes.bool,
  questionIconText: PropTypes.string,
  showButton: PropTypes.bool,
  sub: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SegmentHeader
