import axios from 'axios'
import { useQuery } from 'react-query'
import { axiosConfig } from '../axiosConfig'

type DataResponseType = { currency: string; locale: string }[]

const getLanguagesAndCurrencies = () =>
  axios
    .get<DataResponseType>('/shop-languages-currencies', axiosConfig)
    .then(({ data }) => data)
    .catch(() => [{ locale: 'pl_PL', currency: 'PLN' }])

export const useGetLanguagesAndCurrencies = () =>
  useQuery('shopLanguages', getLanguagesAndCurrencies)
