import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'

import { Step1ChooseTemplate } from '../common/Step1ChooseTemplate'
import { Step3ColorsAndFonts } from '../common/Step3ColorsAndFonts'
import { SubmitButtons } from '../common/SubmitButtons'

import { LivePreview } from './LivePreview'
import { Step2Configuration } from './Step2Configuration'
import { useGetBar } from '../../api/pdd/useGetBar'
import { useGetLanguagesAndCurrencies } from '../../api/common/useGetLanguagesAndCurrencies'

import './CreateNewPDD.scss'

export const CreateNewPDD = ({
  barId,
  onCancel,
  setIsTemplateClicked,
  showStep1Button,
  isSubmitting,
}) => {
  const { resetForm } = useFormikContext()
  const { data: languages } = useGetLanguagesAndCurrencies()

  const [showConfig, setShowConfig] = useState(!!barId)
  const [showBars, setShowBars] = useState(true)
  const [showConfiguration, setShowConfiguration] = useState(true)
  const [showColorsAndFonts, setShowColorsAndFonts] = useState(true)
  const [activeLanguage, setActiveLanguage] = useState(languages[0].locale)

  const { data: barConfig, isLoading: isLoadingBar } = useGetBar(barId)

  useEffect(() => {
    if (barConfig) {
      resetForm({ values: barConfig })
    }
    // eslint-disable-next-line
  }, [barConfig])

  if (isLoadingBar) {
    return <Loader active content='Loading...' />
  }

  return (
    <>
      {/* Krok 1 - wybierz szablon */}
      {!barId && (
        <Step1ChooseTemplate
          appName='PDD'
          setIsTemplateClicked={setIsTemplateClicked}
          setShowBars={setShowBars}
          setShowConfig={setShowConfig}
          showStep1Button={showStep1Button}
        />
      )}
      {showConfig && (
        <>
          <LivePreview
            activeLanguage={activeLanguage}
            isOnlyOneLanguage={languages.length === 1}
            setShowBars={setShowBars}
            showBars={showBars}
          />

          {/* Krok 2 - konfiguracja */}
          <Step2Configuration
            activeLanguage={activeLanguage}
            onClick={setShowConfiguration}
            open={showConfiguration}
            setActiveLanguage={setActiveLanguage}
          />

          {/* Krok 3 - kolory i czcionki */}
          <Step3ColorsAndFonts open={showColorsAndFonts} onClick={setShowColorsAndFonts} />

          {/* Buttony */}
          <SubmitButtons onCancel={onCancel} isLoading={isSubmitting} />
        </>
      )}
    </>
  )
}

CreateNewPDD.propTypes = {
  barId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  setIsTemplateClicked: PropTypes.func.isRequired,
  showStep1Button: PropTypes.bool,
  isSubmitting: PropTypes.bool,
}
