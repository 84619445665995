import React from 'react'
import { Form } from '../form'
import { initialPddValues } from '../../constants/createNew'
import { useCreateBar } from '../../api/pdd/useCreateBar'
import { useUpdateBar } from '../../api/pdd/useUpdateBar'
import { CreateNewPDD } from './CreateNewPDD'
import { pddValidationSchema } from '../../utils/validation'

export const PddTab = ({ barId, onSave, onCancel, setIsTemplateClicked, isTemplateClicked }) => {
  const { mutateAsync: createBar, isLoading: isCreateLoading } = useCreateBar()
  const { mutateAsync: updateBar, isLoading: isUpdateLoading } = useUpdateBar()

  const isSubmitting = isCreateLoading || isUpdateLoading

  const handleCreateBar = async formValues => {
    await createBar(formValues)
    onSave()
  }

  const handleUpdateBar = async formValues => {
    await updateBar({ barId, config: formValues })
    onSave()
  }

  const handleSubmit = async formValues => {
    barId ? await handleUpdateBar(formValues) : await handleCreateBar(formValues)
  }

  return (
    <Form
      initialValues={initialPddValues}
      onSubmit={handleSubmit}
      validationSchema={pddValidationSchema}
    >
      <CreateNewPDD
        barId={barId}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
        setIsTemplateClicked={setIsTemplateClicked}
        showStep1Button={isTemplateClicked}
      />
    </Form>
  )
}
