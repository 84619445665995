import { HOME_DEV_PDD_APP_ID, HOME_PROD_PDD_APP_ID } from '../constants/applications'

const getDataset = name => document.getElementById('root').dataset[name]

export const getShopId = () => getDataset('shopid')

export const getAppId = () => getDataset('applicationid')

export const getPddKey = () => getDataset('pddkey')

export const getAdminName = () => getDataset('adminname')

export const isHomeShop = () => [HOME_DEV_PDD_APP_ID, HOME_PROD_PDD_APP_ID].includes(getAppId())
