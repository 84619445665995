import { useField } from 'formik'
import { Dropdown, Grid } from 'semantic-ui-react'

import './FormDropdown.scss'

export const FormDropdown = props => {
  const [{ value, name }, { error, touched }, { setValue }] = useField(props)
  const shouldShowError = touched && error

  const handleChange = (_, { value }) => {
    setValue(value)
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={6} verticalAlign='middle'>
          <label className='form-dropdown__label'>{props.label}</label>
        </Grid.Column>
        <Grid.Column verticalAlign='middle'>
          <Dropdown
            {...props}
            error={shouldShowError}
            name={name}
            onChange={handleChange}
            value={value}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
