import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Message } from 'semantic-ui-react'

import { isHomeShop } from '../../utils/shop'

import './BeforeStartMessage.scss'

const BeforeStartMessage = ({ onDismiss }) => (
  <Message icon info onDismiss={onDismiss} size='large'>
    <Icon name='info' />
    <Message.Content className='before-start-message__content'>
      <h4>Zanim zaczniesz</h4>
      <p>
        Zanim utworzysz swój pierwszy pasek pamiętaj o skonfigurowaniu opcji darmowej dostawy w
        ustawieniach sklepu, aby system poprawnie ją naliczył. Jak to zrobić znajdziesz{' '}
        <a
          href={
            isHomeShop()
              ? 'https://pomoc.home.pl/baza-wiedzy/do-czego-sluzy-opcja-sprzedaz-dostawy'
              : 'https://www.shoper.pl/help/artykul/poprawnie-skonfigurowac-dostawy'
          }
          target='_blank'
          rel='noopener noreferrer'
        >
          tutaj&nbsp;
          <Icon name='external' size='small' />
        </a>
        . Zwróć uwagę na punkt 3 i pole <strong>Darmowa przesyłka od kwoty</strong>, podana tam
        wartość musi być taka sama jak cel, który ustawisz podczas konfigurowania paska.
      </p>
    </Message.Content>
  </Message>
)

BeforeStartMessage.propTypes = {
  onDismiss: PropTypes.func.isRequired,
}

export default BeforeStartMessage
