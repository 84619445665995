import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Popup } from 'semantic-ui-react'

import './PopupQuestionIcon.scss'

const PopupQuestionIcon = ({ children, text }) => {
  return (
    <div className='popup-question-icon'>
      {children}
      <Popup
        content={text}
        trigger={<Icon className='popup-question-icon__icon' name='question circle outline' />}
        wide
      />
    </div>
  )
}

PopupQuestionIcon.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

export default PopupQuestionIcon
