import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import { axiosConfig } from '../axiosConfig'
import { Bars } from './useCreateBar'

type ToggleBarActive = {
  barId: number
  isActive: boolean
}
const toggleBarActive = ({ barId, isActive }: ToggleBarActive) =>
  axios.patch(`/pso/bars/${barId}`, { isActive }, axiosConfig)

export const useToggleBarActive = () => {
  const queryClient = useQueryClient()

  // @ts-ignore
  const onSuccess = (_, values: ToggleBarActive) => {
    queryClient.setQueryData('psoBars', (oldBars: Bars[] = []) =>
      oldBars.map(bar =>
        bar.id === values.barId
          ? {
              ...bar,
              active: values.isActive,
            }
          : bar
      )
    )
  }

  return useMutation(toggleBarActive, { onSuccess })
}
