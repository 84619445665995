import mixpanel from 'mixpanel-browser'
import { getAppId, getShopId } from './shop'

export const mixpanelInit = () => {
  const isProduction = typeof process !== 'undefined' && process.env?.NODE_ENV === 'production'
  const token = isProduction ? '2f9d55d2f0e5be1f1a70e5efb9d4a828' : 'dev'
  const uniqId = `${getShopId()}:${getAppId()}`

  mixpanel.init(token, {
    debug: !isProduction,
    secure_cookie: true,
    batch_requests: false,
    loaded: mixpanel => {
      mixpanel.identify(uniqId)
    },
  })
}

export const mixpanelTrack = (eventName, options) => {
  mixpanel.track(eventName, options)
}

export const mixpanelTrackLink = (eventName, selector, options) => {
  mixpanel.track_links(selector, eventName, options)
}
