import React from 'react'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

import './TemplateItem.scss'

export const TemplateItem = ({ label, onClick, styles }) => (
  <Segment className='template-item' onClick={onClick(styles)(label)}>
    <div style={styles}>{label}</div>
  </Segment>
)

TemplateItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
}
