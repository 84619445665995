import React from 'react'
import { Button, Checkbox, Popup } from 'semantic-ui-react'

import Table from '../common/Table'
import { mixpanelTrack } from '../../utils/mixpanel'
import { useUpdateBar } from '../../api/pdd/useUpdateBar'
import { useDeleteBar } from '../../api/pdd/useDeleteBar'

export const BarsTablePDD = ({ data, hasSubscriptionEnded, isSomeActive, onEdit }) => {
  const { mutateAsync: updateBar, isLoading: isUpdateLoading } = useUpdateBar()
  const { mutateAsync: deleteBar, isLoading: isDeleteLoading } = useDeleteBar()

  const isLoading = isUpdateLoading || isDeleteLoading

  const handlePublishChange = (id, active) => async () => {
    if (hasSubscriptionEnded && !active) return

    await handlePublishConfirm(id, !active)
    const action = active ? 'Wyłącz' : 'Włącz'
    mixpanelTrack(`${action} PDD`)
  }

  const handlePublishConfirm = async (barId, active) => {
    await updateBar({ barId, active })
  }

  const handleDeleteClick = barId => async () => {
    const message = 'Czy na pewno chcesz usunąć wybrany pasek?'

    const userChoice = window.confirm(message)

    if (userChoice) {
      await deleteBar(barId)
      mixpanelTrack('Usuń PDD')
    }
  }

  const config = [
    {
      width: 2,
      title: 'Lp.',
      render: (_, index) => <p>{index + 1}</p>,
    },
    {
      width: 11,
      title: 'Nazwa',
      field: 'name',
    },
    {
      width: 1,
      title: 'Edytuj',
      render: ({ id }) => <Button basic disabled={isLoading} icon='edit' onClick={onEdit(id)} />,
    },
    {
      width: 1,
      title: 'Usuń',
      render: ({ id }) => (
        <Button basic disabled={isLoading} icon='trash' onClick={handleDeleteClick(id)} />
      ),
    },
    {
      width: 1,
      title: 'Aktywuj',
      render: ({ id, active }) =>
        active || !isSomeActive ? (
          <Checkbox
            checked={active}
            disabled={isLoading}
            onChange={handlePublishChange(id, active)}
            toggle
          />
        ) : (
          <Popup
            basic
            content='Tylko jeden pasek może być aktywny w danym momencie. Aby aktywować, wyłącz pozostałe.'
            trigger={<Checkbox checked={active} disabled={isSomeActive} toggle />}
          />
        ),
    },
  ]

  return <Table config={config} data={data} selectable />
}
