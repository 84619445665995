import React, { useEffect } from 'react'
import { Icon, Segment, SegmentGroup } from 'semantic-ui-react'

import SegmentHeader from './common/SegmentHeader'
import { mixpanelTrack, mixpanelTrackLink } from '../utils/mixpanel'

const Help = () => {
  useEffect(() => {
    mixpanelTrack('Wejście w pomoc')

    mixpanelTrackLink('Klik w instrukcję w PDF', '#pdf-link')
    mixpanelTrackLink('Klik w instrukcję na YT', '#youtube-link')
    mixpanelTrackLink('Klik w kontakt email', '#email-link')

    return () => {
      mixpanelTrack('Wyjście z pomocy')
    }
  }, [])

  return (
    <SegmentGroup>
      <SegmentHeader title='Instrukcja i kontakt' />
      <Segment textAlign='center'>
        <p>
          Instrukcja jak krok po kroku utworzyć nowy pasek{' '}
          <a
            id='pdf-link'
            href='https://codie.pl/files/pdd/Pasek_darmowej_dostawy__Pasek ogloszen__instrukcja.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            tutaj&nbsp;
            <Icon name='external' size='small' />
          </a>
        </p>
        <p>
          Jeśli wolisz filmik z instrukcją to znajdziesz go{' '}
          <a
            id='youtube-link'
            href='https://www.youtube.com/watch?v=Vwp4st0nqPo'
            target='_blank'
            rel='noopener noreferrer'
          >
            tutaj&nbsp;
            <Icon name='external' size='small' />
          </a>
        </p>
        <br />
        <br />
        <p>
          Jeśli potrzebujesz pomocy napisz na{' '}
          <strong>
            <a href='mailto:kontakt@codie.pl' id='email-link'>
              kontakt@codie.pl
            </a>
          </strong>
        </p>
        <small>Odpisujemy tak szybko jak to możliwe ;)</small>
      </Segment>
    </SegmentGroup>
  )
}

export default Help
