import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { isFinite } from 'lodash'
import { Button, Segment, SegmentGroup } from 'semantic-ui-react'
import SegmentHeader from '../common/SegmentHeader'

import './LivePreview.scss'

export const LivePreview = ({ showBars, setShowBars, activeLanguage, isOnlyOneLanguage }) => {
  const { values } = useFormikContext()

  const [isDesktopView, setIsDesktopView] = useState(true)

  const goal = isFinite(+values.texts[activeLanguage]?.goal)
    ? +values.texts[activeLanguage]?.goal
    : ''
  const shouldShowInProgressText = goal === '' || goal !== 0 || goal - 1 >= 0
  const initialMessageBefore = values.texts[activeLanguage]?.initialMessageBefore ?? ''
  const initialMessageAfter = values.texts[activeLanguage]?.initialMessageAfter ?? ''
  const inProgressMessageBefore = values.texts[activeLanguage]?.inProgressMessageBefore ?? ''
  const inProgressMessageAfter = values.texts[activeLanguage]?.inProgressMessageAfter ?? ''
  const reachedGoalMessage = values.texts[activeLanguage]?.reachedGoalMessage ?? ''

  const shouldShowTexts = isOnlyOneLanguage ? true : values.texts[activeLanguage]?.active ?? false
  const shouldShowBars = showBars && shouldShowTexts

  useEffect(() => {
    setShowBars(shouldShowTexts)
  }, [shouldShowTexts, setShowBars])

  const handleClick = () => {
    setShowBars(showBars => !showBars)
  }

  const handleDeviceClick = device => () => {
    setIsDesktopView(device === 'desktop')
  }

  const fontSize = isDesktopView ? values.styles.fontSize : values.styles.fontSizeMobile
  const style = {
    ...values.styles,
    lineHeight: fontSize,
    fontSize,
    width: isDesktopView ? '100%' : '375px', // iPhone 6/7/8 width
    margin: 'auto',
    padding: isDesktopView ? '10px' : '8px',
  }

  return (
    <SegmentGroup className='live-preview'>
      <SegmentHeader
        onClick={handleClick}
        open={showBars}
        title='Podgląd na żywo'
        content={
          <Button.Group basic>
            <Button
              active={isDesktopView}
              icon='desktop'
              onClick={handleDeviceClick('desktop')}
              type='button'
            />
            <Button
              active={!isDesktopView}
              icon='mobile alternate'
              onClick={handleDeviceClick('mobile')}
              type='button'
            />
          </Button.Group>
        }
      />
      {shouldShowBars && (
        <>
          <Segment>
            <div style={style}>{`${initialMessageBefore}${goal}${initialMessageAfter}`}</div>
          </Segment>
          {shouldShowInProgressText && (
            <Segment>
              <div style={style}>{`${inProgressMessageBefore}${
                goal - 1 < 0 ? 0 : goal - 1
              }${inProgressMessageAfter}`}</div>
            </Segment>
          )}
          <Segment>
            <div style={style}>{reachedGoalMessage}</div>
          </Segment>
        </>
      )}
    </SegmentGroup>
  )
}
