import { object, string } from 'yup'
import { pddFormField, psoFormField } from '../constants/createNew'

export const psoValidationSchema = object({
  [psoFormField.name]: string()
    .trim()
    .required('Pole wymagane')
    .max(80, 'Tekst jest zbyt długi. Max 80 znaków.'),
  [psoFormField.linkUrl]: string().when(psoFormField.hasLink, {
    is: true,
    then: string().url('Wpisz poprawny adres URL').required('Pole wymagane'),
  }),
})

export const max100CharsValidation = (value: any) => {
  if (typeof value === 'string') {
    if (value.length > 100) return 'Tekst jest zbyt długi. Max 100 znaków.'
  }
}

export const pddValidationSchema = object({
  [pddFormField.name]: string()
    .trim()
    .required('Pole wymagane')
    .max(80, 'Tekst jest zbyt długi. Max 80 znaków.'),
})
