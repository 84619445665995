import { useState } from 'react'
import { useFormikContext } from 'formik'
import { Button, Segment, SegmentGroup } from 'semantic-ui-react'
import SegmentHeader from '../common/SegmentHeader'

import './LivePreview.scss'

export const LivePreview = ({ showBars, setShowBars, activeLanguage }) => {
  const { values } = useFormikContext()

  const [isDesktopView, setIsDesktopView] = useState(true)

  const textOne = values.texts[activeLanguage]?.textOne ?? ''
  const textTwo = values.texts[activeLanguage]?.textTwo ?? ''
  const textThree = values.texts[activeLanguage]?.textThree ?? ''

  const handleClick = () => {
    setShowBars(showBars => !showBars)
  }

  const handleDeviceClick = device => () => {
    setIsDesktopView(device === 'desktop')
  }

  const fontSize = isDesktopView ? values.styles.fontSize : values.styles.fontSizeMobile
  const style = {
    ...values.styles,
    lineHeight: fontSize,
    fontSize,
    width: isDesktopView ? '100%' : '375px', // iPhone 6/7/8 width
    margin: 'auto',
    padding: isDesktopView ? '8px' : '8px 36px',
  }

  const closeButton = (
    <span className='live-preview__close-btn' style={{ right: isDesktopView ? '16px' : '8px' }}>
      &#215;
    </span>
  )

  return (
    <SegmentGroup className='live-preview'>
      <SegmentHeader
        onClick={handleClick}
        open={showBars}
        title='Podgląd na żywo'
        content={
          <Button.Group basic>
            <Button
              active={isDesktopView}
              icon='desktop'
              onClick={handleDeviceClick('desktop')}
              type='button'
            />
            <Button
              active={!isDesktopView}
              icon='mobile alternate'
              onClick={handleDeviceClick('mobile')}
              type='button'
            />
          </Button.Group>
        }
      />
      {showBars && (
        <>
          {textOne && (
            <Segment>
              <div className='live-preview__bar' style={style}>
                <span className='live-preview__text'>{textOne}</span>
                {values.hasClose && closeButton}
              </div>
            </Segment>
          )}

          {textTwo && (
            <Segment>
              <div className='live-preview__bar' style={style}>
                <span className='live-preview__text'>{textTwo}</span>
                {values.hasClose && closeButton}
              </div>
            </Segment>
          )}

          {textThree && (
            <Segment>
              <div className='live-preview__bar' style={style}>
                <span className='live-preview__text'>{textThree}</span>
                {values.hasClose && closeButton}
              </div>
            </Segment>
          )}
        </>
      )}
    </SegmentGroup>
  )
}
