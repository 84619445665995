import React from 'react'
import PropTypes from 'prop-types'
import { Loader, Table as TableSemantic } from 'semantic-ui-react'

const Table = ({ config, data, loading, selectable }) => {
  const renderHeaderCell = ({ title, width }, i) => (
    <TableSemantic.HeaderCell key={i} width={width}>
      {title}
    </TableSemantic.HeaderCell>
  )

  const renderCell =
    (rowData, ix) =>
    ({ render, field }, i) => {
      const value = render ? render(rowData, ix) : rowData[field]
      return <TableSemantic.Cell key={i}>{value}</TableSemantic.Cell>
    }

  const renderRow = (rowData, i) => (
    <TableSemantic.Row key={i}>{config.map(renderCell(rowData, i))}</TableSemantic.Row>
  )

  return loading ? (
    <Loader active content='Loading...' />
  ) : (
    <TableSemantic selectable={selectable} unstackable>
      <TableSemantic.Header>
        <TableSemantic.Row>{config.map(renderHeaderCell)}</TableSemantic.Row>
      </TableSemantic.Header>
      <TableSemantic.Body>{data.map(renderRow)}</TableSemantic.Body>
    </TableSemantic>
  )
}

Table.propTypes = {
  config: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
}

export default Table
