import React from 'react'
import PropTypes from 'prop-types'
import { Container, Segment } from 'semantic-ui-react'

import { getAdminName } from '../../utils/shop'
import logo from '../../static/images/basket-codie.svg'

import './BrandHeader.scss'

const BrandHeader = ({ title }) => {
  const adminName = getAdminName()

  return (
    <Container className='brand-header' fluid>
      <Segment as='header' className='brand-header__segment' secondary>
        <div className='brand-header__logo-container'>
          <img alt='logo' className='brand-header__img' src={logo} />
          <p className='brand-header__title'>{title}</p>
        </div>
        <div>
          <a href='mailto:kontakt@codie.pl' className='brand-header__email'>
            kontakt@codie.pl
          </a>
          {adminName && (
            <span className='brand-header__greeting'>Dobrze Cię widzieć, {adminName}!</span>
          )}
        </div>
      </Segment>
    </Container>
  )
}

BrandHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default BrandHeader
