import { useState } from 'react'
import { SegmentGroup } from 'semantic-ui-react'
import SegmentHeader from './SegmentHeader'
import { TemplateList } from '../TemplateList'
import { useFormikContext } from 'formik'
import { mixpanelTrack } from '../../utils/mixpanel'

export const Step1ChooseTemplate = ({
  appName,
  setIsTemplateClicked,
  setShowBars,
  setShowConfig,
  showStep1Button,
}) => {
  const [showTemplatesList, setShowTemplatesList] = useState(true)
  const { values, setValues } = useFormikContext()

  const handleHeaderClick = () => {
    setShowTemplatesList(!showTemplatesList)
  }

  const handleTemplateClick = styles => label => () => {
    setIsTemplateClicked(true)
    setShowBars(true)
    setShowConfig(true)
    setShowTemplatesList(false)
    setValues({ ...values, styles })
    mixpanelTrack(`Klik w template ${appName}`, { label })
  }

  return (
    <SegmentGroup>
      <SegmentHeader
        onClick={handleHeaderClick}
        open={showTemplatesList}
        showButton={showStep1Button}
        sub='Każdy szablon można w dalszym kroku edytować.'
        title='Krok 1. Wybierz szablon'
      />
      {showTemplatesList && <TemplateList onClick={handleTemplateClick} />}
    </SegmentGroup>
  )
}
