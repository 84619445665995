import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Segment, SegmentGroup } from 'semantic-ui-react'

import SegmentHeader from '../common/SegmentHeader'
import PopupQuestionIcon from '../common/PopupQuestionIcon'
import { FormCheckbox, FormDropdown, FormInput, FormRadioGroup } from '../form'
import { configs, psoFormField } from '../../constants/createNew'
import { textTimeSecondsOptions } from '../../constants/options'
import { Step2LanguageTabs } from './Step2LanguageTabs'

export const Step2Configuration = ({ open, onClick, activeLanguage, setActiveLanguage }) => {
  const { values, setFieldValue } = useFormikContext()

  const isShowOnCartDisabled = values?.showOnPages === 'cart'

  useEffect(() => {
    if (!values.hasLink) {
      setFieldValue(psoFormField.linkUrl, '')
      setFieldValue(psoFormField.openLinkNewTab, false)
    }
    // eslint-disable-next-line
  }, [values.hasLink])

  const textTwo = values.texts?.[activeLanguage]?.textTwo ?? ''
  const textThree = values.texts?.[activeLanguage]?.textThree ?? ''
  const showTextTimeSeconds = textTwo || textThree

  const handleHeaderClick = () => {
    onClick(!open)
  }

  return (
    <SegmentGroup>
      <SegmentHeader onClick={handleHeaderClick} open={open} title='Krok 2. Konfiguracja' />
      {open && (
        <Segment padded>
          <FormInput
            label='Nazwa - widoczne w panelu tylko dla Ciebie'
            name={psoFormField.name}
            required
          />
          <Step2LanguageTabs setActiveLanguage={setActiveLanguage} />
          <FormRadioGroup
            config={configs.positionConfig}
            name={psoFormField.position}
            title='Pozycja paska na stronie:'
          />
          <FormRadioGroup
            config={configs.showOnPages}
            name={psoFormField.showOnPages}
            title='Pokaż na:'
          />
          <FormCheckbox
            disabled={isShowOnCartDisabled}
            name={psoFormField.showOnCart}
            label='Pokaż na stronie koszyka'
          />
          <PopupQuestionIcon text='Pasek będzie widoczny na górze strony zawsze, nawet podczas przewijania strony.'>
            <FormCheckbox
              name={psoFormField.showOnScroll}
              label='Pokaż podczas przewijania strony'
            />
          </PopupQuestionIcon>
          <PopupQuestionIcon text='Urządzenie mobilne to urządzenie o rozdzielczości ekranu mniejszej niż lub równej 576 px.'>
            <FormCheckbox
              name={psoFormField.showOnMobile}
              label='Pokaż na urządzeniach mobilnych'
            />
          </PopupQuestionIcon>
          <PopupQuestionIcon text='Zaznaczenie tej opcji daje odwiedzającym sklep możliwość ukrycia paska na 24h poprzez kliknięcie krzyżyka z prawej strony paska'>
            <FormCheckbox name={psoFormField.hasClose} label='Pozwól na zamknięcie paska' />
          </PopupQuestionIcon>
          <PopupQuestionIcon text='Dodaje link do paska'>
            <FormCheckbox name={psoFormField.hasLink} label='Link' />
          </PopupQuestionIcon>
          {values.hasLink && (
            <Segment basic style={{ marginTop: 0 }}>
              <FormInput
                label='Link'
                name={psoFormField.linkUrl}
                placeholder='Przykład: https://mojsklep.pl/produkty/obuwie/promocja'
                required={values.hasLink}
              />
              <FormCheckbox
                toggle={false}
                name={psoFormField.openLinkNewTab}
                label='Otwórz link w nowej karcie'
              />
            </Segment>
          )}
          {showTextTimeSeconds && (
            <FormDropdown
              selection
              label='Czas wyświetlania każdego tekstu [sekundy]:'
              name={psoFormField.textTimeSeconds}
              options={textTimeSecondsOptions}
            />
          )}
        </Segment>
      )}
    </SegmentGroup>
  )
}
