import { ChromePicker } from 'react-color'
import { useField } from 'formik'
import { useState } from 'react'
import { Grid } from 'semantic-ui-react'

import './FormColorPicker.scss'

export const FormColorPicker = ({ disableAlpha = true, name, label }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [{ value }, , { setValue }] = useField(name)

  const handleOnClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleChangeComplete = ({ rgb, hex }) => {
    const value = disableAlpha ? hex : `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    setValue(value)
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={10} computer={6}>
          <label>{label}</label>
        </Grid.Column>
        <Grid.Column>
          <div className='form-color-picker__outer-box' onClick={handleOnClick}>
            {displayColorPicker && (
              <div
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                <div className='form-color-picker__dimmer' onClick={handleOnClick} />
                <ChromePicker
                  className='form-color-picker__position'
                  color={value}
                  disableAlpha={disableAlpha}
                  onChangeComplete={handleChangeComplete}
                />
              </div>
            )}
            <div className='form-color-picker__inner-box' style={{ backgroundColor: value }} />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
