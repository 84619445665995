import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { axiosConfig } from '../axiosConfig'
import { PsoConfig } from '../../constants/createNew'

export type Bars = {
  id: number
  active: boolean
  name: string
}

const createBar = (values: PsoConfig) =>
  axios.post('/pso/bars', values, axiosConfig).then(({ data }) => data)

export const useCreateBar = () => {
  const queryClient = useQueryClient()

  const onSuccess = ({ id }: Bars, formValues: PsoConfig) => {
    queryClient.setQueryData('psoBars', (oldBars: Bars[] = []) => [
      ...oldBars,
      { id, active: false, name: formValues.name },
    ])
    queryClient.setQueryData(['psoBar', id], formValues)
  }

  return useMutation(createBar, { onSuccess })
}
