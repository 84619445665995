import { Form } from 'semantic-ui-react'
import { Field } from 'formik'

import './FormRadioGroup.scss'

export const FormRadioGroup = ({ config, name, title }) => {
  return (
    <Form.Group grouped className='form-radio-group'>
      <label>{title}</label>
      {config.map(({ label, value }) => {
        return (
          <Field key={value} name={name} type='radio' value={value}>
            {({ field: { value }, form: { setFieldValue, values } }) => {
              const handleChange = (_, { value }) => {
                setFieldValue(name, value)
              }
              return (
                <Form.Radio
                  label={label}
                  name={name}
                  value={value}
                  checked={value === values[name]}
                  onChange={handleChange}
                />
              )
            }}
          </Field>
        )
      })}
    </Form.Group>
  )
}
