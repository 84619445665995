import { useFormikContext } from 'formik'
import { Form, Popup, Tab } from 'semantic-ui-react'
import { isFinite } from 'lodash'
import { FormCheckbox, FormInput } from '../form'
import PopupQuestionIcon from '../common/PopupQuestionIcon'
import { useGetLanguagesAndCurrencies } from '../../api/common/useGetLanguagesAndCurrencies'
import { pddFormField } from '../../constants/createNew'
import { max100CharsValidation } from '../../utils/validation'

import './Step2LanguageTabs.scss'

export const Step2LanguageTabs = ({ activeLanguage, setActiveLanguage }) => {
  const { values } = useFormikContext()
  const { data: languages } = useGetLanguagesAndCurrencies()

  const goal = isFinite(+values.texts[activeLanguage]?.[pddFormField.goal])
    ? +values.texts[activeLanguage]?.[pddFormField.goal]
    : '100'
  const shouldShowInProgressText = goal === '' || goal !== 0 || goal - 1 >= 0
  const isOnlyOneLanguage = languages.length === 1
  const shouldShowTexts = isOnlyOneLanguage ? true : values.texts[activeLanguage]?.active ?? false

  const handleTabChange = (_, { activeIndex, panes }) => {
    setActiveLanguage(panes[activeIndex].menuItem)
  }

  const panes = languages.map(({ locale, currency }) => {
    return {
      menuItem: locale,
      pane: {
        attached: false,
        key: locale,
        content: (
          <>
            {!isOnlyOneLanguage && (
              <FormCheckbox name={`texts.${locale}.active`} label='Pokaż pasek w tym języku' />
            )}

            {shouldShowTexts && (
              <>
                <FormInput
                  label='Darmowa dostawa od - jeśli nie jest wymagana minimalna wartość zamówienia ustaw 0'
                  name={`texts.${locale}.goal`}
                  placeholder='Wpisz kwotę do darmowej dostawy'
                />

                <sub className='step-2-language-tabs__sub'>
                  <a
                    href='https://www.shoper.pl/help/artykul/poprawnie-skonfigurowac-dostawy'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    Konfiguracja dostaw
                  </a>
                </sub>

                <Form.Group className='step-2-language-tabs__form-group'>
                  <FormInput
                    label='Tekst początkowy - koszyk jest pusty'
                    name={`texts.${locale}.initialMessageBefore`}
                    width={7}
                    validate={max100CharsValidation}
                  />
                  <span className='step-2-language-tabs__popup'>{goal}</span>
                  <FormInput
                    label=' '
                    name={`texts.${locale}.initialMessageAfter`}
                    width={7}
                    validate={max100CharsValidation}
                  />
                </Form.Group>

                {shouldShowInProgressText && (
                  <Form.Group className='step-2-language-tabs__form-group'>
                    <FormInput
                      label='Tekst pośredni - w koszyku jest produkt'
                      name={`texts.${locale}.inProgressMessageBefore`}
                      width={7}
                      validate={max100CharsValidation}
                    />
                    <Popup
                      content='Przykładowa wartość. Obliczana wg wzoru: (cel - aktualna wartość koszyka)'
                      trigger={
                        <span className='step-2-language-tabs__popup help'>
                          {goal - 1 < 0 ? 0 : goal - 1}
                        </span>
                      }
                    />
                    <FormInput
                      label=' '
                      name={`texts.${locale}.inProgressMessageAfter`}
                      width={7}
                      validate={max100CharsValidation}
                    />
                  </Form.Group>
                )}

                <FormInput
                  label='Tekst po osiągnięciu celu - wartość koszyka większa lub równa celowi'
                  name={`texts.${locale}.reachedGoalMessage`}
                  validate={max100CharsValidation}
                />
              </>
            )}
          </>
        ),
      },
    }
  })

  return (
    <>
      <PopupQuestionIcon text='W zależności od języka w jakim użytkownik przegląda sklep oraz wybranej waluty, wyświetlimy odpowiedni pasek.'>
        <label className='step-2-configuration__tab-title'>Języki:</label>
      </PopupQuestionIcon>
      <Tab
        className='step-2-language-tabs__tab'
        menu={{ secondary: true }}
        onTabChange={handleTabChange}
        panes={panes}
        renderActiveOnly={false}
      />
    </>
  )
}
