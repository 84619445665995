import React, { useEffect, useState } from 'react'
import { Container, Grid, Message, Tab } from 'semantic-ui-react'
import Cookies from 'js-cookie'

import Help from './Help'
import { BarsPDD } from './PDD/BarsPDD'
import { BarsPSO } from './PSO/BarsPSO'
import OtherApps from './common/OtherApps'
import BrandHeader from './common/BrandHeader'
import { PsoTab } from './PSO/PsoTab'
import { SubscriptionBox } from './SubscriptionBox'
import BeforeStartMessage from './PDD/BeforeStartMessage'
import { HOW_TO_SETUP_SHOPER_MSG } from '../constants/cookies'
import { mixpanelInit, mixpanelTrack } from '../utils/mixpanel'
import { PddTab } from './PDD/PddTab'
import { useGetLanguagesAndCurrencies } from '../api/common/useGetLanguagesAndCurrencies'

const CREATE_NEW_PANE_INDEX = 2

const App = () => {
  const [isCreateNewPDD, setIsCreateNewPDD] = useState(false)
  const [isCreateNewPSO, setIsCreateNewPSO] = useState(false)
  const [isTemplateClicked, setIsTemplateClicked] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [barId, setBarId] = useState(null)
  const [showMessage, setShowMessage] = useState(false)
  const [hasSubscriptionEnded, setHasSubscriptionEnded] = useState(false)
  // const [showInfoMessage, setShowInfoMessage] = useState(true)

  const { isError: isErrorLanguages } = useGetLanguagesAndCurrencies()

  useEffect(() => {
    mixpanelInit()

    mixpanelTrack('Login')

    if (!Cookies.get(HOW_TO_SETUP_SHOPER_MSG)) setShowMessage(true)

    return () => {
      mixpanelTrack('Logout')
    }
  }, [])

  const handleCreateNewPdd = () => {
    setIsCreateNewPDD(true)
    setActiveTabIndex(CREATE_NEW_PANE_INDEX)
    mixpanelTrack('Utwórz PDD')
  }

  const handleCreateNewPso = () => {
    setIsCreateNewPSO(true)
    setActiveTabIndex(CREATE_NEW_PANE_INDEX)
    mixpanelTrack('Utwórz PSO')
  }

  const handleEditClickPdd = id => () => {
    setBarId(id)
    setIsCreateNewPDD(true)
    setActiveTabIndex(CREATE_NEW_PANE_INDEX)
    mixpanelTrack('Edytuj PDD')
  }

  const handleEditClickPso = id => () => {
    setBarId(id)
    setIsCreateNewPSO(true)
    setActiveTabIndex(CREATE_NEW_PANE_INDEX)
    mixpanelTrack('Edytuj PSO')
  }

  const handleSave = () => {
    setIsTemplateClicked(false)
    setIsCreateNewPDD(false)
    setIsCreateNewPSO(false)
    setBarId(null)
    setActiveTabIndex(0)
    mixpanelTrack('Zapisz template')
  }

  const handleOnTabChange = (_, { activeIndex } = {}) => {
    if (activeIndex === CREATE_NEW_PANE_INDEX) return

    setIsCreateNewPDD(false)
    setIsCreateNewPSO(false)
    setIsTemplateClicked(false)
    setBarId(null)
    setActiveTabIndex(activeIndex || 0)
  }

  const handleMessageDismiss = () => {
    setShowMessage(false)
    Cookies.set(HOW_TO_SETUP_SHOPER_MSG, 'clicked', {
      expires: 365 * 10,
      sameSite: 'None',
      secure: true,
    })
  }

  // const handleInfoMessageDismiss = () => {
  //   setShowInfoMessage(false)
  // }

  const pddTab = {
    menuItem: barId ? 'Edytuj' : 'Nowy pasek darmowej dostawy',
    render: () => (
      <PddTab
        barId={barId}
        onCancel={handleOnTabChange}
        onSave={handleSave}
        setIsTemplateClicked={setIsTemplateClicked}
        isTemplateClicked={isTemplateClicked}
      />
    ),
  }

  const psoTab = {
    menuItem: barId ? 'Edytuj' : 'Nowy pasek ogłoszeń',
    render: () => (
      <PsoTab
        barId={barId}
        onCancel={handleOnTabChange}
        onSave={handleSave}
        setIsTemplateClicked={setIsTemplateClicked}
        isTemplateClicked={isTemplateClicked}
      />
    ),
  }

  const defaultPanes = [
    {
      menuItem: 'Moje paski',
      render: () => (
        <>
          {showMessage && <BeforeStartMessage onDismiss={handleMessageDismiss} />}
          <SubscriptionBox handleSubscriptionEnd={setHasSubscriptionEnded} />
          {/*{showInfoMessage && (*/}
          {/*  <Message visible info size='large' onDismiss={handleInfoMessageDismiss}>*/}
          {/*    <Message.Content>*/}
          {/*      🎉 Od teraz Pasek darmowej dostawy działa we wszystkich językach i walutach Twojego*/}
          {/*      sklepu 🎉*/}
          {/*      <br />*/}
          {/*      Kliknij w "Utwórz nowy", aby utworzyć nowy pasek w kilku językach (jeśli oczywiście*/}
          {/*      posiadasz) lub edytuj aktualny pasek 😊*/}
          {/*    </Message.Content>*/}
          {/*  </Message>*/}
          {/*)}*/}
          {isErrorLanguages && (
            <Message visible error size='large'>
              <Message.Content>
                Nie mogę pobrać języków sklepu. Odśwież stronę lub spróbuj za chwilę.
              </Message.Content>
            </Message>
          )}
          <BarsPDD
            hasSubscriptionEnded={hasSubscriptionEnded}
            onCreateNew={handleCreateNewPdd}
            onEdit={handleEditClickPdd}
          />
          <BarsPSO
            hasSubscriptionEnded={hasSubscriptionEnded}
            onCreateNew={handleCreateNewPso}
            onEdit={handleEditClickPso}
          />
        </>
      ),
    },
    {
      menuItem: 'Instrukcja i kontakt',
      render: () => <Help />,
    },
  ]

  const getPanes = () => {
    if (isCreateNewPDD) return defaultPanes.concat(pddTab)
    if (isCreateNewPSO) return defaultPanes.concat(psoTab)

    return defaultPanes
  }

  return (
    <>
      <BrandHeader title='Pasek darmowej dostawy + Pasek ogłoszeń' />
      <Container>
        <Grid padded stackable>
          <Grid.Row>
            <Grid.Column>
              <Tab
                activeIndex={activeTabIndex}
                grid={{ paneWidth: 13, tabWidth: 3 }}
                menu={{ secondary: true, vertical: true }}
                onTabChange={handleOnTabChange}
                panes={getPanes()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={13} floated='right'>
              <OtherApps />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

export default App
