import { Formik } from 'formik'
import { Form as SemanticForm } from 'semantic-ui-react'

export const Form = ({ children, ...formikProps }) => {
  return (
    <Formik {...formikProps}>
      {({ handleReset, handleSubmit }) => {
        return (
          <SemanticForm onReset={handleReset} onSubmit={handleSubmit}>
            {children}
          </SemanticForm>
        )
      }}
    </Formik>
  )
}
