import { useFormikContext } from 'formik'
import { Segment, SegmentGroup } from 'semantic-ui-react'

import SegmentHeader from '../common/SegmentHeader'
import PopupQuestionIcon from '../common/PopupQuestionIcon'
import { configs, pddFormField } from '../../constants/createNew'
import { FormCheckbox, FormInput, FormRadioGroup } from '../form'
import { Step2LanguageTabs } from './Step2LanguageTabs'

export const Step2Configuration = ({ activeLanguage, onClick, open, setActiveLanguage }) => {
  const { values } = useFormikContext()

  const isShowOnCartDisabled = values?.displayOnSites === 'cart'

  const handleHeaderClick = () => {
    onClick(!open)
  }

  return (
    <SegmentGroup>
      <SegmentHeader onClick={handleHeaderClick} open={open} title='Krok 2. Konfiguracja' />
      {open && (
        <Segment padded>
          <FormInput
            label='Nazwa - widoczne w panelu tylko dla Ciebie'
            name={pddFormField.name}
            required
            placeholder='Możesz tu wpisać cokolwiek'
          />
          <Step2LanguageTabs
            activeLanguage={activeLanguage}
            setActiveLanguage={setActiveLanguage}
          />
          <FormRadioGroup
            config={configs.positionConfig}
            name={pddFormField.position}
            title='Pozycja paska na stronie:'
          />
          <FormRadioGroup
            config={configs.showOnPages}
            name={pddFormField.displayOnSites}
            title='Pokaż na:'
          />
          <FormCheckbox
            disabled={isShowOnCartDisabled}
            label='Pokaż na stronie koszyka'
            name={pddFormField.showOnCart}
          />
          <PopupQuestionIcon text='Pasek będzie widoczny na górze strony zawsze, nawet podczas przewijania strony.'>
            <FormCheckbox
              label='Pokaż podczas przewijania strony'
              name={pddFormField.showOnScroll}
            />
          </PopupQuestionIcon>
          <PopupQuestionIcon text='Urządzenie mobilne to urządzenie o rozdzielczości ekranu mniejszej niż lub równej 576 px.'>
            <FormCheckbox
              label='Pokaż na urządzeniach mobilnych'
              name={pddFormField.showOnMobile}
            />
          </PopupQuestionIcon>
          <PopupQuestionIcon text='Pokaż pasek dopiero, kiedy w koszyku znajdą się produkty o zadanej wartości (w złotych). Domyślnie pasek jest zawsze widoczny.'>
            <FormCheckbox
              label='Pokaż pasek od danej kwoty w koszyku'
              name={pddFormField.showFrom}
            />
          </PopupQuestionIcon>
          {values.showFrom && (
            <FormInput name={pddFormField.showFromAmount} type='number' min='0' />
          )}
        </Segment>
      )}
    </SegmentGroup>
  )
}
