import { Tab } from 'semantic-ui-react'
import { FormInput } from '../form'
import { max100CharsValidation } from '../../utils/validation'
import PopupQuestionIcon from '../common/PopupQuestionIcon'
import { useGetLanguagesAndCurrencies } from '../../api/common/useGetLanguagesAndCurrencies'

import './Step2LanguageTabs.scss'

export const Step2LanguageTabs = ({ setActiveLanguage }) => {
  const { data: languages = [{ locale: 'pl_PL', currency: 'PLN' }] } =
    useGetLanguagesAndCurrencies()

  const handleTabChange = (_, { activeIndex, panes }) => {
    setActiveLanguage(panes[activeIndex].menuItem)
  }

  const panes = languages.map(({ locale }) => {
    return {
      menuItem: locale,
      pane: {
        attached: false,
        key: locale,
        content: (
          <>
            <FormInput
              className='step-2-language-tabs__first-text'
              label='Tekst pierwszego ogłoszenia (opcjonalne)'
              name={`texts.${locale}.textOne`}
              placeholder='Kliknij teraz i sprawdź nasze promocje!'
              validate={max100CharsValidation}
            />
            <FormInput
              label='Tekst drugiego ogłoszenia (opcjonalne)'
              name={`texts.${locale}.textTwo`}
              placeholder='Tylko dziś darmowa dostawa na wszystkie zamówienia! Spiesz się!'
              validate={max100CharsValidation}
            />
            <FormInput
              label='Tekst trzeciego ogłoszenia (opcjonalne)'
              name={`texts.${locale}.textThree`}
              placeholder='Tekst trzeciego ogłoszenia'
              validate={max100CharsValidation}
            />
          </>
        ),
      },
    }
  })

  return (
    <>
      <PopupQuestionIcon text='W zależności od języka w jakim użytkownik przegląda sklep, wyświetlimy odpowiedni. Jeśli pozostawisz wszystkie pola puste w danym języku - nie pokażemy paska.'>
        <label className='step-2-language-tabs__tab-title'>Języki:</label>
      </PopupQuestionIcon>
      <Tab
        className='step-2-language-tabs__tab'
        menu={{ secondary: true }}
        onTabChange={handleTabChange}
        panes={panes}
        renderActiveOnly={false}
      />
    </>
  )
}
