import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { axiosConfig } from '../axiosConfig'
import { Bars } from './useCreateBar'

const deleteTemplate = (barId: number) => axios.delete(`/pso/bars/${barId}`, axiosConfig)

export const useDeleteBar = () => {
  const queryClient = useQueryClient()

  const onSuccess = (_: any, id: number) => {
    queryClient.removeQueries(['psoBar', id], { exact: true })

    queryClient.setQueryData('psoBars', (oldBars: Bars[] = []) =>
      oldBars.filter(bar => bar.id !== id)
    )
  }

  return useMutation(deleteTemplate, { onSuccess })
}
