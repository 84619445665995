import React from 'react'
import PropTypes from 'prop-types'
import { Header, Loader, Segment, SegmentGroup } from 'semantic-ui-react'

import SegmentHeader from '../common/SegmentHeader'
import { BarsTablePSO } from './BarsTablePSO'
import { useGetBars } from '../../api/pso/useGetBars'

import './BarsPSO.scss'

export const BarsPSO = ({ onCreateNew, onEdit, hasSubscriptionEnded }) => {
  const { data: bars = [], isLoading } = useGetBars()

  const isEmptyBarList = bars.length === 0
  const isBarsDisabled = isLoading || bars.length > 10
  const isSomeActive = bars.some(({ active }) => active)

  return (
    <SegmentGroup>
      <SegmentHeader
        disabled={isBarsDisabled}
        title='Moje paski ogłoszeń'
        buttonLabel='Utwórz nowy'
        onClick={onCreateNew}
        primary
        questionIconText='Pasek ogłoszeń to dodatkowy pasek, z możliwością zamknięcia przez użytkownika, który służy do wyświetlania wiadomości w sklepie. Np. o aktualnych promocjach lub urlopie.'
      />
      <Segment className='bars-pso__content'>
        {isLoading ? (
          <Loader active content='Loading...' />
        ) : isEmptyBarList ? (
          <Segment placeholder>
            <Header
              as='h2'
              className='bars-pso__placeholder'
              content='Brak pasków'
              subheader='Aby utworzyć nowy pasek kliknij przycisk powyżej.'
              textAlign='center'
            />
          </Segment>
        ) : (
          <BarsTablePSO
            bars={bars}
            hasSubscriptionEnded={hasSubscriptionEnded}
            isSomeActive={isSomeActive}
            onEdit={onEdit}
          />
        )}
      </Segment>
    </SegmentGroup>
  )
}

BarsPSO.propTypes = {
  hasSubscriptionEnded: PropTypes.bool.isRequired,
  onCreateNew: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}
